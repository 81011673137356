import axios from "axios";
import { IInstruction } from "@/types/Instruction";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *Instruction service
 * Provides UI business logic for instruction
 */
export class InstructionService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(
    includes = '["Warehouse","District","User","InstructedCommodities","InstructedCommodities.Commodity"]'
  ): Promise<IServiceResponse> {
    try {
      const inclStr = includes != "" ? `/?includes=${includes}` : "";
      const result: any = await axios.get(
        `${this.API_URL}/Instruction${inclStr}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async get(
    id: number,
    includes = '["Warehouse","District","Activity","User","InstructedCommodities","InstructedCommodities.Commodity"]'
  ): Promise<IServiceResponse> {
    try {
      const inclStr = includes != "" ? `/?includes=${includes}` : "";
      const result: any = await axios.get(
        `${this.API_URL}/Instruction/${id}/${inclStr}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async create(instruction: IInstruction): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/Instruction`,
        instruction,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async delete(id: number): Promise<IServiceResponse> {
    try {
      const result = await axios.get(
        `${this.API_URL}/instruction/delete/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async update(instruction: IInstruction): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/instruction/Update`,
        instruction,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async approve(id: number): Promise<IServiceResponse> {
    try {
      const result = await axios.get(
        `${this.API_URL}/instruction/approve/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
}
