
import { Component, Vue } from "vue-property-decorator";
import { LoadingPlanService } from "@/services/loading-plan-service";
import { DispatchService } from "@/services/dispatch-service";
import { ActivityService } from "@/services/activity-service";
import { RequisitionService } from "@/services/requisition-service";
import { ProjectService } from "@/services/project-service";
import { InstructionService } from "@/services/instruction-service";
import { ApiService } from "@/services/api-services";
import { Defaults } from "@/helpers/Defaults";
import { Functions } from "@/helpers/Functions";
import { ILoadingPlan } from "@/types/LoadingPlan";
import { IDispatch } from "@/types/Dispatch";
import { ITruckDriver } from "@/types/TruckDriver";
import { IUser } from "@/types/User";
import { IActivity } from "@/types/Activity";
import { IProject } from "@/types/Project";
import { IRequisition } from "@/types/Requisition";
import { IInstruction } from "@/types/Instruction";
import { IResponseData } from "@/types/ResponseData";
import newDispatchModal from "@/components/DispatchModal.vue";
import newInstructionModal from "@/components/InstructionModal.vue";
import viewLoadingPlanModal from "@/components/ViewLoadingPlanModal.vue";
import newLoadingPlanModal from "@/components/LoadingPlanModal.vue";
import newRequisitionModal from "@/components/DisasterModal.vue";
import confirmDeleteModal from "@/components/ConfirmDeleteModal.vue";
import editLoadingPlanModal from "@/components/EditLoadingPlanModal.vue";

@Component({
  name: "Project",
  components: {
    newLoadingPlanModal,
    newRequisitionModal,
    newDispatchModal,
    newInstructionModal,
    viewLoadingPlanModal,
    confirmDeleteModal,
    editLoadingPlanModal,
  },
})
export default class Project extends Vue {
  show = { showOverlay: false }; //this is for new loading plan
  edit = { showOverlay: false }; // this is for loading plans
  dispatchOverlay = { show: false };
  confirmDelete = { showOverlay: false };
  newRequisitionShow = { showOverlay: false };
  newInstructionShow = { showOverlay: false };
  userString = localStorage.getItem("user");
  isAdmin = false;
  defaults = new Defaults();
  functions = new Functions();
  apiService = new ApiService();
  loadingPlanService = new LoadingPlanService();
  requisitionService = new RequisitionService();
  dispatchService = new DispatchService();
  activityService = new ActivityService();
  projectService = new ProjectService();
  instructionService = new InstructionService();
  loadingPlanResponseData: IResponseData = this.defaults.responseData;
  requisitionResponseData: IResponseData = this.defaults.responseData;
  instructionResponseData: IResponseData = this.defaults.responseData;
  dispatchResponseData: IResponseData = this.defaults.responseData;
  commodityResponseData: IResponseData = this.defaults.responseData;
  districtResponseData: IResponseData = this.defaults.responseData;
  transporterResponseData: IResponseData = this.defaults.responseData;
  warehouseResponseData: IResponseData = this.defaults.responseData;
  includes =
    '["Commodity","Warehouse","Transporter","District","User","Activity","Dispatches"]';
  users: IUser[] = [];
  activities: IActivity[] = [];
  projects: IProject[] = [];
  pageSize = 10;
  requisitionPageNumber = 1;
  loadingPlanPageNumber = 1;
  currentModal = {
    id: 0,
    name: "",
    showOverlay: false,
  };
  loadingPlan = this.defaults.loadingPlan;
  dispatch = this.defaults.dispatch;
  instruction = this.defaults.instruction;
  driver: ITruckDriver = this.defaults.driver;
  drivers: ITruckDriver[] = [];
  loading = true;
  loadingPlansSkeleton = true;
  requisitionsSkeleton = true;
  requisitionsCountSkeleton = true;
  get groupedActivities(): any {
    return this.functions.groupBy(this.activities, (i) => i.project.name);
  }
  get loadingPlans(): ILoadingPlan[] {
    return this.loadingPlanResponseData.data;
  }
  async initialize(): Promise<void> {
    let requestParams = this.defaults.requestParams;
    requestParams.isPaged = false;
    let response = await this.apiService.getAll("commodity", requestParams);
    if (!response.isSuccess && response.status == 401)
      this.visitRoute("/logout");
    this.commodityResponseData = response.data;
    response = await this.apiService.getAll("district", requestParams);
    this.districtResponseData = response.data;
    response = await this.apiService.getAll("transporter", requestParams);
    this.transporterResponseData = response.data;
    response = await this.apiService.getAll("warehouse", requestParams);
    this.warehouseResponseData = response.data;
    response = await this.apiService.getAll("user", requestParams);
    this.users = response.data;
    requestParams.isPaged = true;
    requestParams.includes =
      '["Commodity","Warehouse","Transporter","District","User","Activity","Dispatches"]';
    response = await this.apiService.getAll("loading plan", requestParams);
    this.loadingPlanResponseData = response.data;
    this.loadingPlan = this.loadingPlanResponseData.data[0];
    this.loading = false;
    this.loadingPlansSkeleton = false;
    requestParams.includes =
      '["Instruction","Activity","Activity.Project","Requester","RequestedCommodities"]';
    response = await this.apiService.getAll("requisition", requestParams);
    this.requisitionResponseData = response.data;
    this.requisitionsCountSkeleton = false;
    this.requisitionsSkeleton = false;
    requestParams.includes =
      '["Driver","LoadingPlan","LoadingPlan.Transporter","LoadingPlan.Warehouse","LoadingPlan.District","LoadingPlan.Commodity"]';
    response = await this.apiService.getAll("dispatch", requestParams);
    this.dispatchResponseData = response.data;
    requestParams.includes =
      '["Warehouse","District","User","InstructedCommodities","InstructedCommodities.Commodity"]';
    response = await this.apiService.getAll("instruction", requestParams);
    this.instructionResponseData = response.data;
    this.activities = await this.activityService.getAll();
    this.projects = await this.projectService.getAll();
    this.loadingPlan.activity = this.activities[1];
  }
  async created(): Promise<void> {
    this.checkIfAdmin();
    await this.initialize();
    //console.log(this.commodities);
  }
  checkIfAdmin(): void {
    if (this.userString != null) {
      const user = JSON.parse(this.userString);
      const userRoles = user.roles;
      if (userRoles.includes("admin")) {
        this.isAdmin = true;
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async setLoadingPlans(pageNumber: number): Promise<void> {
    this.loadingPlansSkeleton = true;
    let requestParams = this.loadingPlanResponseData.requestParams;
    requestParams.pageNumber = pageNumber;
    const response = await this.apiService.getAll("loadingPlan", requestParams);
    this.loadingPlanResponseData = response.data;
    this.loadingPlansSkeleton = false;
  }
  async setRequisitions(pageNumber: number): Promise<void> {
    this.requisitionsSkeleton = true;
    let requestParams = this.requisitionResponseData.requestParams;
    requestParams.pageNumber = pageNumber;
    const response = await this.apiService.getAll("requisition", requestParams);
    this.requisitionResponseData = response.data;
    this.requisitionsSkeleton = false;
  }
  dispatched(loadingPlan: ILoadingPlan): number {
    let dispatches = loadingPlan.dispatches ?? [];
    let dispatched = dispatches.reduce((qty: number, item) => {
      return qty + item.quantity;
    }, 0);
    return +(dispatched / 20).toFixed(2);
  }
  closeModal(id: number): void {
    const modal = this.deleteLoadingPlanModalId(id);
    this.$bvModal.hide(modal.name);
  }
  visitRoute(link: string): void {
    this.$router.push(link);
  }
  dispatchModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "createDispatchModal" + i,
      showOverlay: false,
    };
  }
  instructionModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "createInstructionModal" + i,
      showOverlay: false,
    };
  }
  viewLoadingPlanModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "viewLoadingPlanModal" + i,
      showOverlay: false,
    };
  }
  deleteLoadingPlanModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "deleteLoadingPlanModal" + i,
      showOverlay: false,
    };
  }
  editLoadingPlanModalId(item: ILoadingPlan): {
    id: number;
    name: string;
    showOverlay: boolean;
    dispatched: number;
  } {
    return {
      id: item.id,
      name: "editLoadingPlanModal" + item.id,
      showOverlay: false,
      dispatched: this.dispatched(item),
    };
  }
  makeToast(
    message = "Oh Snap! something went wrong. Please try again later.",
    title = "",
    variant = "danger"
  ): void {
    this.$bvToast.toast(message, {
      title: title,
      autoHideDelay: 5000,
      appendToast: true,
      variant: variant,
    });
  }
  async deleteLoadingPlan(id: number): Promise<void> {
    let message = "You can't delete this resource!";
    let title = "Deleting Loading Plan";
    let variant = "danger";
    if (!this.isAdmin) this.makeToast(message, title, variant);
    this.confirmDelete.showOverlay = true;
    const result = await this.loadingPlanService.delete(id);
    this.confirmDelete.showOverlay = false;
    this.$bvModal.hide(this.deleteLoadingPlanModalId(id).name);
    message = result.message;
    title = "Deleting Loading Plan";
    variant = "danger";
    if (result.isSuccess) {
      variant = "success";
      this.loading = true;
      this.loadingPlansSkeleton = true;
      const loadingPlanResponse = await this.apiService.getAll(
        "loading plan",
        this.loadingPlanResponseData.requestParams
      );
      this.loadingPlanResponseData = loadingPlanResponse.data;
      this.loading = false;
      this.loadingPlansSkeleton = false;
    }
    this.makeToast(message, title, variant);
  }
  async updateLoadingPlan(loadingPlan: ILoadingPlan): Promise<void> {
    this.edit = { showOverlay: true };
    const result = await this.loadingPlanService.update(loadingPlan);
    let message = result.message;
    let title = "Updating Loading Plan";
    let variant = "danger";
    if (result.isSuccess) {
      variant = "success";
    }
    this.edit = { showOverlay: false };
    const currentModal = this.editLoadingPlanModalId(loadingPlan);
    this.$bvModal.hide(currentModal.name);
    this.makeToast(message, title, variant);
  }
  async submitNewLoadingPlan(loadingPlan: ILoadingPlan): Promise<void> {
    this.show = { showOverlay: true };
    loadingPlan.commodity = loadingPlan.commodity || this.defaults.commodity;
    loadingPlan.warehouse = loadingPlan.warehouse || this.defaults.warehouse;
    loadingPlan.transporter =
      loadingPlan.transporter || this.defaults.transporter;
    loadingPlan.district = loadingPlan.district || this.defaults.district;
    loadingPlan.commodityId = loadingPlan.commodity.id;
    loadingPlan.warehouseId = loadingPlan.warehouse.id;
    loadingPlan.transporterId = loadingPlan.transporter.id;
    loadingPlan.districtId = loadingPlan.district.id;
    const result = await this.loadingPlanService.create(loadingPlan);
    let message = result.message;
    let title = "Creating a new Loading Plan";
    let variant = "danger";
    if (result.isSuccess) {
      variant = "success";
    }
    this.show = { showOverlay: false };
    this.$bvModal.hide("createLoadingPlanModal");
    this.makeToast(message, title, variant);
  }

  async submitNewInstruction(instruction: IInstruction): Promise<void> {
    this.newInstructionShow = { showOverlay: true };
    const result = await this.instructionService.create(instruction);
    this.currentModal = this.instructionModalId(instruction.requisitionId);
    if (!result.isSuccess && result.status == 401) this.visitRoute("/logout");
    this.$bvModal.hide(this.currentModal.name);
    let message = "";
    let title = "Creating a new goods release instruction";
    let variant = "danger";
    if (result.isSuccess) {
      message = "New goods release instruction has been created successfully";
      variant = "success";
    }
    this.newInstructionShow = { showOverlay: false };
    this.makeToast(message, title, variant);
  }
  async submitNewDispatch(dispatch: IDispatch): Promise<void> {
    dispatch.loadingPlan = dispatch.loadingPlan || this.defaults.loadingPlan;
    this.currentModal = this.dispatchModalId(dispatch.loadingPlan.id);
    this.dispatchOverlay.show = true;
    const result = await this.dispatchService.create(dispatch);
    this.dispatchOverlay.show = false;
    this.$bvModal.hide(this.currentModal.name);
    let message = result.message;
    let title = "Creating a new Dispatch";
    let variant = "danger";
    if (result.isSuccess) {
      this.dispatch = this.defaults.dispatch;
      variant = "success";
    }
    this.makeToast(message, title, variant);
  }
  async submitNewRequisition(requisition: IRequisition): Promise<void> {
    this.newRequisitionShow = { showOverlay: true };
    const result = await this.requisitionService.create(requisition);
    if (!result.isSuccess && result.status == 401) this.visitRoute("/logout");
    this.$bvModal.hide("disasterModal");
    let message = result.message;
    let title = "Creating a new Requisition";
    let variant = "danger";
    if (result.isSuccess) {
      message = "New requisition created successfully";
      variant = "success";
    }
    this.show = { showOverlay: false };
    this.makeToast(message, title, variant);
  }
  prepend(value: any, array: Array<any>): Array<any> {
    let newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }
}
