import axios from "axios";
import { IServiceResponse } from "@/types/ServiceResponse";
import { IRequestParams } from "@/types/RequestParams";
/*
 *API service
 * Provides UI business logic for all classes
 */
export class ApiService {
  API_URL = process.env.VUE_APP_API_URL;

  getClassName(name: string): string {
    switch (name.toLowerCase()) {
      case "loadingplan":
      case "loading plan":
      case "loading-plan":
        name = "LoadingPlan";
        break;
      case "requisition":
        name = "Requisition";
        break;
      case "instruction":
        name = "Instruction";
        break;
      case "dispatch":
        name = "Dispatch";
        break;
      case "instructeddispatch":
      case "instructed dispatch":
      case "instructed-dispatch":
        name = "InstructedDispatch";
        break;
      case "receipt":
        name = "Receipt";
        break;
      case "instructedreceipt":
      case "instructedReceipt":
      case "instructed receipt":
      case "instructed-receipt":
        name = "InstructedReceipt";
        break;
      case "user":
        name = "User";
        break;
      case "unit":
        name = "Unit";
    }
    return name;
  }

  public async getAll(
    name: string,
    requestParams: IRequestParams
  ): Promise<IServiceResponse> {
    try {
      //const inclStr = includes != "" ? `/?includes=${includes}` : "";
      const className = this.getClassName(name);
      let inclStr = "";
      for (const [key, val] of Object.entries(requestParams)) {
        inclStr += val !== "" ? `&${key}=${val}` : "";
      }
      if (inclStr.startsWith("&")) inclStr = inclStr.substring(1).trim();
      inclStr = inclStr !== "" ? `/?${inclStr}` : inclStr;
      const result = await axios.get(`${this.API_URL}/${className}${inclStr}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async create(name: string, item: any): Promise<IServiceResponse> {
    try {
      const className = this.getClassName(name);
      const result = await axios.post(`${this.API_URL}/${className}`, item, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async delete(name: string, id: number): Promise<IServiceResponse> {
    try {
      const className = this.getClassName(name);
      const result = await axios.get(
        `${this.API_URL}/${className}/delete/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async update(name: string, item: any): Promise<IServiceResponse> {
    try {
      const className = this.getClassName(name);
      const result = await axios.post(
        `${this.API_URL}/${className}/Update`,
        item,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
}
