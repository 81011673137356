
import { Component, Vue } from "vue-property-decorator";
import { IInstruction } from "@/types/Instruction";
import { Defaults } from "@/helpers/Defaults";
import { IDonatedCommodity } from "@/types/DonatedCommodity";
import { IInstructedCommodity } from "@/types/InstructedCommoditiy";
const InstructionProps = Vue.extend({
  props: {
    transporters: [],
    commodities: [],
    warehouses: [],
    districts: [],
    activities: [],
    instruction: Object,
    requisition:Object,
    modal: Object,
    instructionOverlay: Object,
  },
});
@Component({
  name: "InstructionModal",
  components: {},
})
export default class InstructionModal extends InstructionProps {
  show = false;
  defaults = new Defaults();
  project = "Lean Season Response";
  reliefItems: IInstructedCommodity[] = [];
  transporterState = null;
  warehouseState = null;
  districtState = null;
  name = "";
  //defaults = new Defaults();
  //instruction: IInstruction = this.defaults.instruction;

  save(): void {
    let instruction = this.instruction;
    instruction.requisitionId = this.requisition.id;
    instruction.warehouseId = instruction.warehouse.id;
    instruction.transporterId = instruction.transporter.id;
    instruction.districtId = instruction.district.id;
    instruction.instructedCommodities = this.reliefItems;
    this.$emit("save:instruction", instruction);
  }
}
